<template>
    <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2794_5155)">
            <path
                d="M21.9613 4.65854C21.8714 4.58854 21.7668 4.53992 21.6554 4.51636C21.5439 4.49281 21.4286 4.49494 21.3181 4.5226L15.5872 5.9551L9.83563 3.07885C9.67537 2.99892 9.49181 2.97897 9.31812 3.0226L3.31812 4.5226C3.15587 4.56316 3.01183 4.65679 2.90889 4.7886C2.80595 4.92042 2.75003 5.08286 2.75 5.2501V18.7501C2.75002 18.8641 2.77601 18.9765 2.82599 19.0789C2.87598 19.1814 2.94864 19.271 3.03847 19.3412C3.12831 19.4113 3.23294 19.46 3.34442 19.4837C3.45591 19.5073 3.57131 19.5052 3.68187 19.4776L9.41281 18.0451L15.1644 20.9214C15.2688 20.9728 15.3836 20.9998 15.5 21.0001C15.5613 21.0001 15.6224 20.9925 15.6819 20.9776L21.6819 19.4776C21.8441 19.437 21.9882 19.3434 22.0911 19.2116C22.194 19.0798 22.25 18.9174 22.25 18.7501V5.2501C22.25 5.13603 22.224 5.02346 22.174 4.92095C22.124 4.81844 22.0512 4.7287 21.9613 4.65854ZM9.5 16.5001C9.43868 16.5001 9.3776 16.5077 9.31812 16.5226L4.25 17.7892V5.83604L9.41281 4.5451L9.5 4.58823V16.5001ZM20.75 18.1642L15.5872 19.4551L15.5 19.412V7.5001C15.5613 7.50037 15.6224 7.49313 15.6819 7.47854L20.75 6.21104V18.1642Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_2794_5155">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
